<template>
    <div class="card">
        <div class="card-header border-0 pt-6 bg-white row m-0 sticky">
            <div class="d-flex p-0">
                <div class="card-title text-gray-600">
                    <div class="filter d-flex align-items-center position-relative fs-6">
                        <div class="filter-item">
                            <span>CARI</span>
                            <el-input v-model="filter.search" placeholder="Search" clearable @clear="onFilter()" @keyup.enter="onFilter()"/>
                        </div>
                        <div class="filter-item">
                            <span>GROUPS</span>
                            <el-select v-model="filter.group_id" placeholder="Pilih group" clearable @change="onFilter()">
                                <el-option v-for="(o, i) in listGroup" :key="i" :value="o.id" :label="o.name"/>
                            </el-select>
                        </div>
                        <div class="d-flex">
                            <button class="btn btn-icon btn-light-primary me-3" @click="onFilter(), currentPage = 1"><i class="fas fa-search"></i></button>
                            <button class="btn btn-icon btn-light-danger" @click="resetFilter(), currentPage = 1"><i class="fas fa-times"></i></button>
                        </div>
                    </div>
                </div>
                <div class="card-toolbar" style="margin-left:auto">
                    <div class="d-flex justify-content-end" data-kt-table-toolbar="base">
                        <button type="button" class="btn btn-light me-3" @click="refreshMenu()">Refresh</button>
                    </div>
                </div>
            </div>
            <div class="my-3 p-0 text-gray-600 text-uppercase fw-bolder" v-if="filterActive">
                <span>Hasil filter</span>
            </div>
        </div>
        <div class="card-body pt-0 tab-content">
            <div ref="tableRef" class="table-fixed-header">
                <table class="table table-rounded table-row-dashed cursor-pointer table-hover" :class="{'overlay': loadingTable, 'overlay-block': loadingTable}">
                    <thead>
                        <tr class="text-start fw-bolder fs-7 gs-0 text-gray-400 border-gray-200 text-uppercase">
                            <th class="w-50px">#</th>
                            <th>Nama</th>
                            <th>Deskripsi</th>
                            <th>Total</th>
                            <th class="text-end">Action</th>
                        </tr>
                    </thead>
                    <tbody class="fw-bold text-gray-600" v-for="(item, i) in tableData" :key="i">
                        <tr @click="showGroupMenu(item.id, i)" :id="'parent_'+i" class="cursor-pointer">
                            <td class="w-50px">{{ item.index }}</td>
                            <td class="text-capitalize">{{ item.name }}</td>
                            <td class="text-capitalize">{{ item.description }}</td>
                            <td>{{ item.total }}</td>
                            <td class="indicator-arrow text-end w-175px">
                                <div class="d-flex justify-content-end">
                                    <button class="btn btn-primary btn-sm ms-1" title="Show detail">
                                        <i class="ms-2 fas fa-chevron-down view"></i>
                                        <span class="spinner-border spinner-border-sm loadingInside d-none" role="status"></span>
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr class="table-dropdown pb-5" :id="'child_'+i">
                            <td></td>
                            <td v-if="tableGroupMenu.length > 0" colspan="4" style="padding-right: 0 !important;">
                                <button class="btn btn-sm btn-white btn-active-light-primary me-2 mb-2 border" v-for="(menu, j) in tableGroupMenu" :key="j">
                                        <span>{{ menu.title }}</span>
                                        <el-popconfirm
                                            confirm-button-text="OK"
                                            cancel-button-text="No"
                                            :icon="InfoFilled"
                                            title="Are you sure to delete this?"
                                            @confirm="onDelete(menu)"
                                        >
                                            <template #reference>
                                                <span>
                                                    <i class="ms-4 fas fa-times text-danger text-hover-dark"></i>
                                                </span>
                                            </template>
                                        </el-popconfirm>
                                </button>
                                <span class="btn btn-sm btn-primary me-2 mb-2 " @click="getRow(item)">
                                    <i class="ms-2 fas fa-plus"></i>
                                </span>
                            </td>
                            <td v-else colspan="4" style="padding-right: 0 !important;">
                                <span class="btn btn-sm btn-light me-2">Tidak ada data</span>
                                <span class="btn btn-sm btn-primary" @click="getRow(item)">
                                    <i class="ms-2 fas fa-plus"></i>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                    <div class="overlay-layer card-rounded bg-dark bg-opacity-5" v-if="loadingTable">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </table>
            </div>
            <div class="text-center my-3" v-show="tableData.length > 0">
                <el-pagination 
                    background 
                    :page-size="50"
                    layout="prev, pager, next" 
                    :page-count="pagination.total_page" 
                    @next-click="handlePageChange" 
                    @prev-click="handlePageChange" 
                    @current-change="handlePageChange" 
                    @update:current-page="pagination.page"
                />
            </div>
        </div>
        <el-dialog v-model="modal" :title="modalTitle" width="600px" :close-on-click-modal="true">
            <el-form
                ref="formRef"
                :model="tempData"
                class="form w-100 my-5"
            >
                <div class="scroll-y me-n7 pe-7">
                    <div class="fv-row mb-7">
                        <label class="required fs-6 fw-bold mb-2">Group</label>
                        <el-form-item
                            class="fv-row mb-7"
                            prop="group_id"
                            :rules="[
                              { required: true, message: 'Group is required' },
                            ]"
                        >
                            <el-input v-model="tempData.group_name" name="price" class="form-control p-0 border-0" placeholder="Group" size="large" disabled></el-input>
                        </el-form-item>
                    </div>
                    <div class="fv-row mb-7">
                        <label class="required fs-6 fw-bold mb-2">Menu</label>
                        <el-form-item
                          class="fv-row mb-7"
                          prop="menu_id"
                          :rules="[
                            { required: true, message: 'Menu is required' },
                          ]"
                        >
                            <el-select
                                v-model="tempData.menu_id"
                                multiple
                                filterable
                                allow-create
                                default-first-option
                                :reserve-keyword="false"
                                placeholder="Pilih menu"
                                class=""
                            >
                                <el-option
                                  v-for="(item, i) in listMenu"
                                  :key="i"
                                  :label="item.title"
                                  :value="item.id"
                                />
                            </el-select>
                        </el-form-item>
                    </div>
                </div>
                <div class="mt-4 mb-2 text-center">
                    <button type="reset" class="btn btn-light me-3" @click="modal = false">Cancel</button>
                    <button
                        type="button"
                        ref="submitButton"  
                        class="btn btn-primary"
                        :data-kt-indicator="loadingSubmit ? 'on' : 'off'"
                        @click="onSubmit(formRef)"
                    >
                        <span class="indicator-label"> Submit </span>

                        <span class="indicator-progress">
                            Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                </div>
            </el-form>
        </el-dialog>

        <el-dialog v-model="viewImageVisible" :title="viewImageTitle" width="50%" lock-scroll>
            <inner-image-zoom :src="viewImageSrc" />
        </el-dialog>
    </div>
</template>

<script>
import { defineComponent, onMounted, ref, reactive, watchEffect, watch, computed, onUpdated } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatIDR } from "@/helpers/functions";
//import TableLite from 'vue3-table-lite/src/components/TableLite.vue'
import Datatable from "@/components/kt-datatable/KTDatatable.vue"
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import InnerImageZoom from 'vue-inner-image-zoom';
import { InfoFilled } from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox, ElLoading, Elpagination, FormInstance, FormRules } from 'element-plus'
import { useRoute, useRouter } from 'vue-router'
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";

export default defineComponent({
    components: { 
        //Datatable,
        // Field,
        // Form,
        //ErrorMessage,
        'inner-image-zoom': InnerImageZoom,
    },
    setup() {
        const store = useStore();
        const route = useRoute();
        const router = useRouter();

        const img_url_mockup = ref(process.env.VUE_APP_IMAGE_MOCKUPS)
        const img_url_desain= ref(process.env.VUE_APP_IMAGE_DESAINS)
        const img_url_logo= ref(process.env.VUE_APP_IMAGE_LOGOS)

        const loadingSubmit = ref(false);
        const loadingTable = ref(false);
        const loadingSubTable = ref(false)
        const tableRef = ref(null)
        
        const formRef = ref(null)

        const filterActive = ref(false)
        const filter = reactive({
            group_id: null,
            search: null,
        })
        
        const tableData = reactive([]);
        const tableGroupMenu = reactive([]);
        const totalRecordCount = ref(0);
        const listGroup = reactive([]);
        const listMenu = reactive([]);

        const rowIndex = ref(null)
        const rowData = ref(null)

        const tempData = reactive(Yup.object().shape({
            group_id: null,
            group_name: '',
            menu_id: Yup.string().required().label('Menu'),
        }))
        
        const submitType = ref('')

        const modal = ref(false)
        const modalTitle = ref('')

        const getRow = async(data) => {
            const loading = ElLoading.service({text: 'tunggu'})

            try {

                await getListMenu()
                
                tempData.group_id = data.id
                tempData.group_name = data.name
                tempData.menu_id = []
                modalTitle.value = 'Add Group Menu'
                modal.value = true

            } catch(e) {
                console.log(e)
            }

            loading.close()
        }

        const onFilter = async (type) => {
            filterActive.value = false
        
            loadingTable.value = true

            await getData()

            loadingTable.value = false

            Object.values(filter).every(o => o == null || o.length == 0)
                ? filterActive.value = false
                : filterActive.value = true
        }

        const resetFilter = async (type) => {
          Object.keys(filter).map(key => filter[key] = null)

          loadingTable.value = true
          filterActive.value = false

          await getData()

          loadingTable.value = false
        }

        // PAGINATION
        const pagination = ref({
            per_page: 50,
            total_page: 0,
            total_row: 0,
            page: 1,
        })

         const handlePageChange = async (page) => {
            loadingTable.value = true
            tableRef.value && (tableRef.value.scrollTop = 0) // Reset scroll position

            await getData(page)
            loadingTable.value = false
        }

        const getListMenu = async() => {
            try {
                await ApiService.get("menu")
                .then(({ data }) => {
                    if(data.error) {
                        throw new Error('Error getting list menu')
                    }

                    Object.assign(listMenu, data.data)
                })
                .catch(({ response }) => {
                    throw new Error(response.data.messages.error)
                });

            } catch(e) {
                console.log(e)
            }
        }

        const showGroupMenu = async (id, index) => {
            try {
                tableGroupMenu.splice(0)
                loadingSubTable.value = true

                tempData.group_name = null
                tempData.menu_id = null
                tempData.group_id = null

                // Current edit row
                rowIndex.value = index
                rowData.value = id

                document.getElementById('parent_'+index).querySelector('.loadingInside').classList.remove('d-none');
                document.querySelector('#parent_'+index+' > .indicator-arrow i.view').classList.add('d-none');

                document.getElementById('parent_'+index).closest('#parent_'+index).classList.toggle('table-active');
                document.getElementById('child_'+index).classList.add('table-active');

                // Fetch data
                if(document.querySelector('#parent_'+index+' > .indicator-arrow i.view').classList.contains('fa-chevron-down')) {
                    await ApiService.get("group_menu_detail/"+id)
                    .then(({ data }) => {
                        if(data.error) {
                            throw new Error('Error getting group menu detail')
                        }

                        totalRecordCount.value = data.data.length
                        Object.assign(tableGroupMenu, data.data)
                    })
                    .catch(({ response }) => {
                        throw new Error(response.data.messages.error)
                    });
                }

                document.getElementById('child_'+index).classList.toggle('table-dropdown-show');

                if(document.getElementById('child_'+index).classList.contains('table-dropdown-show')) {
                    document.querySelector('#parent_'+index+' > .indicator-arrow i.view').classList.add('fa-chevron-up');
                    document.querySelector('#parent_'+index+' > .indicator-arrow i.view').classList.remove('fa-chevron-down');
                } else {
                    document.querySelector('#parent_'+index+' > .indicator-arrow i.view').classList.add('fa-chevron-down');
                    document.querySelector('#parent_'+index+' > .indicator-arrow i.view').classList.remove('fa-chevron-up');
                }

                tableData.filter((o, i) => {
                    if(i != index) {
                        document.getElementById('child_'+i).classList.remove('table-dropdown-show');
                        document.getElementById('parent_'+i).closest('#parent_'+i).classList.remove('table-active');
                        document.querySelector('#parent_'+i+' > .indicator-arrow i.view').classList.remove('fa-chevron-up');
                        document.querySelector('#parent_'+i+' > .indicator-arrow i.view').classList.add('fa-chevron-down');
                    }
                })

            } catch(e) {
                Swal.fire({
                    text: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000
                });
            }

            loadingSubTable.value = false
            document.getElementById('parent_'+index).querySelector('.loadingInside').classList.add('d-none');
            document.querySelector('#parent_'+index+' > .indicator-arrow i.view').classList.remove('d-none');
        }

        const getData = async (page = 1) => {
            try {
                loadingTable.value = true
                await tableData.splice(0)

                let group = filter.group_id ? '&group='+filter.group_id : '';
                let search = filter.search ? '&search='+filter.search : '';

                await ApiService.get("group_menu?page=" + page + group + search)
                .then(async ({ data }) => {
                    pagination.value = data.data.pagination
                    Object.assign(tableData, data.data.data)
                })
                .catch(({ response }) => {
                    console.log('Error getting group menu');
                });

            } catch(e) {
                console.error(e)
            }

            loadingTable.value = false
        }

        const onSubmit = async(formEl) => {
            try {
                if (!formEl) return

                await formEl.validate( async(valid, fields) => {
                    loadingSubmit.value = true;

                    if (valid) {
                        //console.log(tempData.menu_id)
                        await ApiService.post('add_group_menu', { group_id: tempData.group_id, menu_id: tempData.menu_id})
                        .then(async({ data }) => {
                            if(data.error) {
                                throw new Error(data.messages)
                            }

                            modal.value = false

                            Swal.fire({
                                title: data.messages,
                                icon: "success",
                                buttonsStyling: false,
                                showConfirmButton: false,
                                timer: 2000,
                            })

                            tempData.group_id = null
                            tempData.group_name = null
                            tempData.menu_id = null

                            await getData()
                        })
                        .catch(({ response }) => {
                            if(response.data) {
                                Swal.fire({
                                    title: "Error",
                                    text: response.data.messages,
                                    icon: "error",
                                    buttonsStyling: false,
                                    showConfirmButton: false,
                                    timer: 2000
                                });
                            }
                        }); 

                    } else {
                        throw new Error('Please check form before submit.')
                    }

                    loadingSubmit.value = false
                })

            } catch(e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        }

         const onDelete = async(data) => {
            try {
                await ApiService.post('delete_group_menu/'+ data.id)
                .then(async({ data }) => {
                    if(data.error) {
                        throw new Error(data.messages)
                    }

                    Swal.fire({
                        title: data.messages,
                        icon: "success",
                        buttonsStyling: false,
                        showConfirmButton: false,
                        timer: 2000,
                    })

                    await getData()
                })
                .catch((response) => {
                    throw new Error(response)
                });
                

            } catch(e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        }

        const getListGroup = async() => {
            try {
                await ApiService.get('groups')
                .then(async({ data }) => {
                    if(data.error) {
                        throw new Error(data.messages)
                    }

                    Object.assign(listGroup, data.data)
                })
                .catch((response) => {
                    throw new Error(response)
                });

            } catch(e) {
                console.error(e)
            }
        }

        const refreshMenu = () => {
            try {
                store.dispatch(Actions.MENU);

                Swal.fire({
                    title: 'Refresh success',
                    icon: "success",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000
                });

            } catch(e) {
                console.error(e)
            }
        }

        onMounted(() => {
            setCurrentPageBreadcrumbs(route.meta.title, [route.meta.title]);

            getData()
            getListGroup()
        })

        return {
            formatIDR,
            formRef,
            img_url_mockup, img_url_desain, img_url_logo,
            loadingTable, loadingSubTable, tableRef, loadingSubmit,
            modal, modalTitle, getRow,
            pagination, handlePageChange,
            onFilter, resetFilter, filter, filterActive,
            listGroup, showGroupMenu, listMenu,
            tableData, tableGroupMenu, totalRecordCount, tempData, submitType, 
            onSubmit, onDelete, refreshMenu,
        }
    }
})
</script>